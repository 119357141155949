export type TypeAPlans = "A1" | "A2";
export type TypeBPlans = "B1" | "B2" | "B2R" | "B2 | B2R";
export type TypeCPlans = "C1" | "C2" | "C3" | "C4";
export type TypeDPlans = "D1" | "D2" | "D3" | "D4";
export type TypeEPlans = "E1";
export type TypeFPlans = "F1" | "F2";
export type TypeAllPlans =
  | TypeAPlans
  | TypeBPlans
  | TypeCPlans
  | TypeDPlans
  | TypeEPlans
  | TypeFPlans;

export type TAdditional =
  | "Flex"
  | "Den"
  | "Study"
  | "Media"
  | "Office"
  | "Studio"
  | "Entertainment";

export type TypePlanFilter =
  | "All"
  | "Studio"
  | "JR 2 Bed"
  | "1 Bed"
  | "1 Bed + Den"
  | "2 Bed";

export const planFilters: Array<TypePlanFilter> = [
  "All",
  "Studio",
  "1 Bed",
  "1 Bed + Den",
  "JR 2 Bed",
  "2 Bed",
];

export interface IPlan {
  slug: TypeAllPlans;
  title: TypeAllPlans;
  src: string;
  bedroom: "Studio" | 1 | 2 | 3 | 4;
  bathroom: 1 | 1.5 | 2 | 2.5;
  additional?: TAdditional | boolean;
  interior: Array<number> | number;
  exterior: { [key: string]: Array<number> | number } | Array<number> | number;
  filter: TypePlanFilter;
}

const Floorplans: Array<IPlan> = [
  {
    slug: "A1",
    title: "A1",
    src: "/floorplans/plan_A1.svg",
    additional: "Den",
    bedroom: 1,
    bathroom: 1,
    interior: 794,
    exterior: [44, 418],
    filter: "1 Bed + Den",
  },
  {
    slug: "A2",
    title: "A2",
    src: "/floorplans/plan_A2.svg",
    additional: "Den",
    bedroom: 1,
    bathroom: 1,
    interior: 683,
    exterior: {
      "3F": 166,
      "4-5F": 45,
    },
    filter: "1 Bed + Den",
  },
  {
    slug: "B1",
    title: "B1",
    src: "/floorplans/plan_B1.svg",
    additional: "Den",
    bedroom: 1,
    bathroom: 1,
    interior: 794,
    exterior: [147, 170],
    filter: "JR 2 Bed",
  },
  {
    slug: "B2",
    title: "B2 | B2R",
    src: "/floorplans/plan_B2-B2R.svg",
    additional: "Den",
    bedroom: 1,
    bathroom: 1,
    interior: 736,
    exterior: {
      "3F": 121,
      "4-5F": [44, 49],
    },
    filter: "1 Bed + Den",
  },
  {
    slug: "B2R",
    title: "B2 | B2R",
    src: "/floorplans/plan_B2-B2R.svg",
    additional: "Den",
    bedroom: 1,
    bathroom: 1,
    interior: 736,
    exterior: {
      "3F": 121,
      "4-5F": [44, 49],
    },
    filter: "JR 2 Bed",
  },
  {
    slug: "C1",
    title: "C1",
    src: "/floorplans/plan_C1-C2.svg",
    bedroom: 1,
    bathroom: 1,
    interior: [757, 767],
    exterior: [165, 350],
    filter: "1 Bed",
  },
  {
    slug: "C2",
    title: "C2",
    src: "/floorplans/plan_C1-C2.svg",
    bedroom: 1,
    bathroom: 1,
    interior: [757, 767],
    exterior: [165, 350],
    filter: "1 Bed",
  },
  {
    slug: "C3",
    title: "C3",
    src: "/floorplans/plan_C3-C4.svg",
    bedroom: 1,
    bathroom: 1,
    interior: [646, 656],
    exterior: {
      "3F": 164,
      "4F": 45,
    },
    filter: "1 Bed",
  },
  {
    slug: "C4",
    title: "C4",
    src: "/floorplans/plan_C3-C4.svg",
    bedroom: 1,
    bathroom: 1,
    interior: [646, 656],
    exterior: {
      "3F": 164,
      "4F": 45,
    },
    filter: "1 Bed",
  },
  {
    slug: "D1",
    title: "D1",
    src: "/floorplans/plan_D1-D2.svg",
    bedroom: "Studio",
    bathroom: 1,
    interior: [512, 523],
    exterior: [58, 77],
    filter: "Studio",
  },
  {
    slug: "D2",
    title: "D2",
    src: "/floorplans/plan_D1-D2.svg",
    bedroom: "Studio",
    bathroom: 1,
    interior: [512, 523],
    exterior: [58, 77],
    filter: "Studio",
  },
  {
    slug: "D3",
    title: "D3",
    src: "/floorplans/plan_D3-D4.svg",
    bedroom: "Studio",
    bathroom: 1,
    interior: [443, 454],
    exterior: {
      "3F": [71, 85],
      "4-5F": 55,
    },
    filter: "Studio",
  },
  {
    slug: "D4",
    title: "D4",
    src: "/floorplans/plan_D3-D4.svg",
    bedroom: "Studio",
    bathroom: 1,
    interior: [443, 454],
    exterior: {
      "3F": [71, 85],
      "4-5F": 55,
    },
    filter: "Studio",
  },
  {
    slug: "E1",
    title: "E1",
    src: "/floorplans/plan_E1.svg",
    bedroom: 1,
    bathroom: 1,
    interior: 574,
    exterior: {
      "3F": 166,
      "4-5F": 45,
    },
    filter: "1 Bed",
  },
  {
    slug: "F1",
    title: "F1",
    src: "/floorplans/plan_F1-F2.svg",
    bedroom: 2,
    bathroom: 2,
    interior: [906, 916],
    exterior: [473, 522],
    filter: "2 Bed",
  },
  {
    slug: "F2",
    title: "F2",
    src: "/floorplans/plan_F1-F2.svg",
    bedroom: 2,
    bathroom: 2,
    interior: [906, 916],
    exterior: [473, 522],
    filter: "2 Bed",
  },
];

export default Floorplans;
