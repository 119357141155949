import {createAsyncThunk, createSlice, PayloadAction} from "@reduxjs/toolkit";
import {IInitialState, IUnit} from "./IInitialState";
import {RootState} from "./store";
import _ from "lodash";
import Floorplans, {IPlan,} from "../components/pages/floor-plans/floorplans-data";
import APIFetchUnits from "./API/fetchUnits";

/** ************************
 * ? Initialize State
 ************************ */
const initialState: IInitialState = {
  menu: false,
  units: [],
  floorplan: Floorplans[0],
};

/** ************************
 * ? Fetch Units Data
 ************************ */
export const fetchUnits = createAsyncThunk(
  "presentation/fetchUnits",
  async () => {
    const response = await APIFetchUnits();
    return response;
  }
);

/** ************************
 * ? Presentation Slice
 ************************ */
export const presentationSlice = createSlice({
  name: "presentation",
  initialState,
  reducers: {
    /**********************
     * * Open Menu
     * *********************/
    openMenu: {
      reducer: (state) => {
        state.menu = true;
      },
      prepare: () => ({
        payload: null,
      }),
    },

    /***********************
     * * Close Menu
     * *********************/
    closeMenu: {
      reducer: (state) => {
        state.menu = false;
      },
      prepare: () => ({
        payload: null,
      }),
    },
    /***********************
     * * Update Floorplans
     * *********************/
    updateFloorplan: {
      reducer: (state, action: PayloadAction<{ floorplan: IPlan | null }>) => {
        state.floorplan = action.payload.floorplan;
      },
      prepare: ({ floorplan }: { floorplan: IPlan | null }) => ({
        payload: { floorplan },
      }),
    },
  },
  extraReducers: {
    /***********************
     * * Callback of Fetch All Units
     * *********************/
    [fetchUnits.fulfilled.toString()]: (state, action: any) => {
      const data = action.payload;
      const ordered = _.orderBy(
        data,
        (u: IUnit) => {
          return +u.unit;
        },
        "asc"
      );
      state.units = ordered;
    },
  },
});

export const { openMenu, closeMenu, updateFloorplan } =
  presentationSlice.actions;

export const getMenu = (state: RootState) => state.presentation.menu;
export const selectFloorplan = (state: RootState) =>
  state.presentation.floorplan;
export const selectUnits = (state: RootState) => state.presentation.units;
export default presentationSlice.reducer;
