import { configureStore } from '@reduxjs/toolkit';
import createRootReducer from './rootReducer';

const reducer = createRootReducer();

const createStore = () => {
  const store = configureStore({
    reducer,
  });
  return store;
};

const store = createStore();

export type RootState = ReturnType<typeof store.getState>;

export type Store = ReturnType<typeof createStore>;

export default store;
