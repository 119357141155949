import { combineReducers } from '@reduxjs/toolkit';
import presentation from './presentationSlice';

const createRootReducer = () => {
  return combineReducers({
    presentation,
  });
};

export default createRootReducer;
