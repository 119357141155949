import React from "react";
import { Provider } from "react-redux";

import store from "./src/redux/store";

type Props = {
  element: React.ReactNode;
};
export default ({ element }: Props) => {
  return <Provider store={store}>{element}</Provider>;
};
